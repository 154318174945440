import Vue from 'vue'
import VueRouter from 'vue-router'
import VueApexCharts from 'vue-apexcharts' 

Vue.use(VueRouter,VueApexCharts)

const routes = [
  {
     path: '/', component: () => import('../views/screens/login.vue'),
   
      // path: '/',
      // component: () => import(/* webpackChunkName: "home" */ '../views/screens/updatedailog.vue'),
    
  },

  { path: '*', beforeEnter: (to, from, next) => { next('/404') } },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "layout" */ '../views/errors/404.vue')
  },
  {
      path: "/layout",
      name: "layout",
      component: () => import(/* webpackChunkName: "layout" */ '../views/layout.vue'),
      children: [
        {
          path: '/Dashboard',
          component: () => import(/* webpackChunkName: "home" */ '../views/screens/dashboard.vue'),
        },
        {
          path: '/Employee',
          component: () => import(/* webpackChunkName: "orders" */ '../views/screens/employee.vue'),
        },
        {
          path: '/Project',
          component: () => import(/* webpackChunkName: "settings" */ '../views/screens/projects.vue'),
        },
        {
          path: '/Task',
          component: () => import(/* webpackChunkName: "settings" */ '../views/screens/task.vue'),
        },
        {
          path: '/Teams',
          component: () => import(/* webpackChunkName: "settings" */ '../views/screens/teams.vue'),
        },
      
        {
          path: '/Customer',
          component: () => import(/* webpackChunkName: "settings" */ '../views/screens/customer.vue'),
        },
         {
          path: '/Reports',
          component: () => import(/* webpackChunkName: "settings" */ '../views/screens/taskreport.vue'),
         },
         {
          path: '/Status',
          component: () => import(/* webpackChunkName: "settings" */ '../views/screens/status-dashboard.vue'),
         },
         {
          path: '/emp-login',
          component: () => import(/* webpackChunkName: "settings" */ '../views/screens/employee-login.vue'),
         },
        
      ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


  router.beforeEach((to, from, next) =>{
    var isAuthanticate = localStorage.getItem('empName') && localStorage.getItem('settokenId') ? true : false;
    if(isAuthanticate){
      next()
    }
    else{
      if(to.path == '/'){
        next()
    } else {
      next({ path: "/" })
    }
    }
  })
export default router
