import axios from "axios";
import store from '@/store'
import Vue from 'vue';

// var BASEURL = 'http://103.231.42.211:8080/SasTaskManagerService/'
var BASEURL = 'https://rest.amoga.tech/SasTaskManagerService/'
// var BASEURL2 = 'http://192.168.1.8:8081/one'

const AXIOS = axios.create({
  baseURL: `${BASEURL}`,
});

// const AXIOS2 = axios.create({
//     baseURL: `${BASEURL2}`,
//   });
  

const service = new Vue({
    
    data: () => ({
      headers: {
        "Content-Type": "application/json",
      },
    }),
    
    methods: {
    getAuthHeaders() {
            var userTokenID = JSON.parse(localStorage.getItem('userEmail'))
            var userSessionID = JSON.parse(localStorage.getItem('settokenId'))
            let headers = {
               'Content-Type': 'application/json',
                //Authorization: "Bearer " + store.state.userData.emp_email + " " + store.state.userData.tokenkey,
                Authorization: "Bearer " + userTokenID + " " + userSessionID,
            };
            return headers;
        },



     getLogindetails: async function (jsonObj) {
        return await AXIOS.post('employee/empLogin', jsonObj, {
            headers: this.headers
        });
    },

    createTask: async function (jsonObj) {
      return await AXIOS.post('task/insertTask', jsonObj,{
       headers: this.getAuthHeaders()
        });
    
    },

    projectDetails: async function (jsonObj) {
        return await AXIOS.post('project/getprojectDetailsList', jsonObj,{
            headers: this.getAuthHeaders()
        });

    },

    employeeDetails: async function(jsonObj){
        return await AXIOS.post('employee/getListEmployeeDetails', jsonObj,{
            headers: this.getAuthHeaders()
        });
    },

    customerDetails: async function(jsonObj){
       return await AXIOS.post('customerdetails/insertCustomerDetails', jsonObj,{
           headers: this.headers
       })
   },

   projectCreation: async function(jsonObj){
    return await AXIOS.post('project/insertProjectDetails', jsonObj,{
        headers: this.getAuthHeaders()
    })
  },

    getReportsdetails: async function(jsonObj) {
    return await AXIOS.post('reports/getReports' , jsonObj,{
        headers: this.getAuthHeaders()
    })
   },
   
    getCustomerlist : async function(jsonObj) {
       return await AXIOS.post('customerdetails/getCustomerDetails', jsonObj,{
           headers: this.headers
       })
   },
  
   getTasklist : async function(jsonObj){
    return await AXIOS.post('task/getTaskList', jsonObj,{
        headers: this.getAuthHeaders()
    })
   },
  
   updateTask: async function(jsonObj){
        return await AXIOS.post('taskUpdate/updateTaskUpdates', jsonObj,{
            headers: this.getAuthHeaders()
        })
    },

   userCheckIn: async function(jsonObj){
        return await AXIOS.post('checkinout/insertCheckin', jsonObj,{
            headers: this.getAuthHeaders()
        })
    },

   userCheckedOut: async function(jsonObj){
        return await AXIOS.post('checkinout/updateCheckout', jsonObj,{
             headers: this.getAuthHeaders()
        })
    },

   userRecheck: async function(jsonObj){
      return await AXIOS.post('checkinout/reloadcheckin', jsonObj,{
          headers: this.getAuthHeaders()
      })
  },
  getHoursdetails: async function(jsonObj) {
    return await AXIOS.post('reports/getTaskHourReport' , jsonObj,{
        headers: this.getAuthHeaders()
    })
   },
   showcomments: async function(jsonObj) {
    return await AXIOS.post('reports/getComments' , jsonObj,{
        headers: this.getAuthHeaders()
    })
   },

//    userCount : async function(jsonObj){
//        return await AXIOS2.post('test/check', jsonObj,{
//         headers: this.headers
//        })
//    },
//    userActiveStatus: async function(jsonObj){
//        return await AXIOS2.post('active1/active', jsonObj,{
//         headers: this.headers
//        })
//    }
}
});


export default service;

