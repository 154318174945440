import router from '../router'
import Vue  from 'vue';
const expire = new Vue({
    data() {
        return {
            nState: null,
        };
    },
    methods: {
        openNotification(options) {
            this.$notification.open(options);
            this.nState = JSON.stringify(options, null, 2);
        },
    }
});

export default expire

