import router from '@/router'
import store from '@/store'
import Vue  from 'vue';
const errorHandling = new Vue({
    data(){
        return{
            nState: null,
        };
    },
    methods:{
        openNotification(options) {
            this.$notification.open(options);
            this.nState = JSON.stringify(options, null, 2);
          },
          errLog(msg) {
            if (msg.response.status >= 400 && msg.response.status < 500 && store.state.counter == 0) {
                 this.toaster('' , "danger" , msg.response.data) 
                 store.commit('setCounterIncrease',1) 
                this.localClear()
            } else if (msg.response.status >= 500) {
                this.toaster('' , "danger" , 'Something went wrong. Please try after sometime... ')
            }

        },
        localClear() {
            store.dispatch('wsConnection/inValidateSession', '', { root:true })
            store.commit('wsConnection/SetIsLogout' , true)
            store.commit('authtication/setUserSessionId', '')
            store.commit('authtication/setUserDto', '')
            var userId = JSON.parse(localStorage.getItem("currentUser"));
            localStorage.clear();
            localStorage.setItem('currentUser', JSON.stringify(userId))
            let userfetch = {
                userId: userId,
              }
            store.dispatch('wsConnection/websocketClose')

           !!userId ? store.dispatch('authtication/getUserId',userfetch) : ''
            router.push('/').catch(() => { });
        },
        sessionExpire(){
            if(store.state.counter == 0){
             store.commit('setSessionCounter',1) 
           this.toaster('' , "danger" , 'Session Expired...')
            this.localClear();
        }
        },
        toaster(title, type, values){
            if(title == ""){
                this.openNotification({
                    type: type,
                    message: values,
                    duration: 5000,
                });
            }else{
                this.openNotification({
                    title: title,
                    type: type,
                    message: values,
                    duration: 5000,
                });
            }
            
        }
    }
});

export default errorHandling