import Vue from 'vue'
import Vuex from 'vuex'
import service from '../store/js/http-service'
import userData from '../store/modules/userlogin'
import task from '../store/modules/task.js'
import expire from '../mixins/expire.js'
import router from '../router'

// import errorHandling from "../store/js/error-handling";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    projectName: '',
    managerName: '',
    id: '',
    taskLists: [],
    data: [],
    customerLists: [],
    projecttList: [],
    employeeList: [],
    reportData: [],
    projectNames: [],
    RecordList: [],
    managerNames: [],
    customerNames: [],
    employeRecord: [],
    loading: false,
    dailogloading: false,
    individualTaskList: [],
    projectData: [],
    UserTasks: [],
    employeeList: [],
    managerName: '',
    Updatetask: [],
    individual: [],
    HourTask: [],
    TaskhourList: [],
    hoursData: [],
    showcomments: [],
    allComments: [],
    userCount: [],
    setActivUser: [],
    empRecords:[],
    noData: false,
    emprole: "",
    updateTaskPanel:'close',
    expandPanel: false,
    panelWidth : '25%',
    checkoutID:'',
    empdata: "",
    records:"",
    empName:"",
    TOTALCOUNT: {
      title: {
        show: true,
        textStyle: {
          color: "grey",
          fontSize: 28
        },
        text: "",
        left: "center",
        top: "center"
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        backgroundColor: '#c7cfd9',
        textStyle: {
          "color": "#000"
        }
      },
      legend: {

        data: ["Check-IN", "Check-OUT", "Total-Users", ],
        bottom: 10,
        textStyle: {
          "color": "#6e6e6e"
        }
      },
      grid: {
        left: "2%",
        right: "2%",
        bottom: 60,
        containLabel: true,
      },
      toolbox: {

        feature: {
          magicType: {
            show: true,
            title: '',
            type: ["line", "bar", ]
          },
          restore: {
            show: false
          },
          saveAsImage: {
            show: true,
            title: 'Save'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
          data: [],
          type: 'line',
          smooth: true,
          name: 'Check-IN'
        },
        {
          data: [],
          type: 'line',
          smooth: true,
          name: 'Check-OUT'
        },
        {
          data: [],
          type: 'line',
          smooth: true,
          name: 'Total-Users'
        }
      ]
    }
  },
  mutations: {
    setName(state, payload) {
      state.projectName = payload
    },

    setTaskrecords(state, payload) {
      state.taskLists = payload
    },

    projectRecordList(state, payload) {
      state.projectNames = payload
    
    },
    setCustomerDetails(state, payload) {
      state.customerResponse = payload
    },

    reportsDetails(state, payload) {
      state.userDetailsData = payload
    },

    setReportDetails(state, payload) {
      state.projectNames = payload
    },

    setRecordList(state, payload) {
      state.RecordList = payload
     
    },

    hoursdetails(state, payload) {
      state.userDetailsData = payload
    },

    setTaskreport(state, payload) {
      state.projectNames = payload
    },

    setTaskhourList(state, payload) {
      var checkinStatus = []
      var checkoutStatus = []
      state.TaskhourList = payload
      payload.forEach(el => {
        // state.TaskhourList.sort((a, b) => (a.emp_name > b.emp_name ? 1 : -1));
        state.TaskhourList.sort((newvalue, lastvalue) => (newvalue.emp_name > lastvalue.emp_name ? 1 : -1))
        if(el.emp_id == el.emp_id){
        checkinStatus.push(el.check_in)
        checkoutStatus.push(el.check_out);
         var checickin  = checkinStatus 
         var checkout = checkoutStatus 
         this.checkoutID = el.check_out
         localStorage.setItem('checkin', JSON.stringify(checickin)[1]);
         localStorage.setItem('checkoutid', JSON.stringify(this.checkoutID));
        }
       
      });
      let data = payload.reduce(function (r, a) {
        r[a.emp_name] = r[a.emp_name] || [];
        r[a.emp_name].push(a);
        return r;
    }, Object.create(null));

    },
    setCustomerList(state, payload) {
      state.customerLists = payload
    },
    
    setProjectList(state, payload) {
      state.projecttList = payload
    },
 
    setEmployeeNames(state, payload) {

      state.employeeList = payload
     
    },

    setEmployeeRecords(state, payload) {
      state.employeRecord = payload
   
    },

    setManagerData(state, payload) {
      state.managerNames = payload

    },
    setCustomerNames(state, payload) {
      state.customerNames = payload
    },
    setProjectData(state, payload) {
      state.projectNames = payload

    },

    projectRecord(state, payload) {
      state.projectData = payload

    },
    setManagerName(state, payload) {
      if (payload == '') {
        state.managerName = payload
      } else {
        state.projecttList.forEach(el => {
          if (payload == el.project_id) {
            state.managerName = el.manager_name
          }
        })
      }

    },
    setProjectDetails(state, payload) {
      state.projectResponse = payload
    },
    setLoading(state, data) {
      state.loading = data
    },
    setDailogloading(state, data) {
      state.dailogloading = data
    },
    setIndividualTaskList(state, payload) {
      state.individualTaskList = payload
    },

    TaskDetails(state, payload) {
      state.UserTasks = payload
    },
    UPDATE_TASK(state, payload) {
      state.Updatetask = payload
    },

    setComments(state, payload) {
      state.allComments = payload
    },
    
    SET_USER_COUNT(state, payload) {
      state.userCount = payload
      this.commit('COUNT_DETAILS', state.userCount)
    },

    COUNT_DETAILS(state, payload) {
      state.TOTALCOUNT.series[0].data = []
      state.TOTALCOUNT.series[1].data = []
      state.TOTALCOUNT.series[2].data = []
      payload.forEach(el => {
        // state.USERCOUNT.xAxis.data = ['data']
      state.TOTALCOUNT.series[0].data.push(el.totalCheckIn_count)
      state.TOTALCOUNT.series[1].data.push(el.totalCheckOut_count)
      state.TOTALCOUNT.series[2].data.push(el.total_count)  
      })
    },

    // SET_ACTIVE_LIST(state, payload){
    //   state.setActivUser = payload
    // },

    setUpdateTaskPanel ( state, payload ) {
      state.updateTaskPanel = payload
    },
   
    setExpandPanel ( state, payload ) {
      var empRoleId = (localStorage.getItem('adminRole') == 1)
      state.expandPanel = payload
      state.panelWidth = payload ? 'calc(100% - 74px)' : '25%'
    },
    
    setReport(state, payload){
      state.empRecords = payload
     
    }

  },

  actions: {

    /**
     * Method to get Employee Details
     * @author Saran Raj
     * @since 12/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    async getEmployeeData({
      commit,
      state,
      rootGetters
    }, payload) {
      commit('setLoading', true)

      await service.employeeDetails().then((response) => {
        setTimeout(() => {
          commit('setLoading', false)

          if (response.status == 200) {
            if (response.data['status'] == 1) {
              commit("setEmployeeRecords", response.data['result'])

              localStorage.setItem('role', JSON.stringify(response.data['result']))
              var employeeList = []
              let employeRole = []
              let data = response.data['result']

              for (let idx of response.data['result']) {
                employeeList.push(idx['emp_name']);
                if (idx['emp_id'] == idx['emp_id']) {
                  employeRole.push(idx['emp_role'])

                }
                if (idx['emp_role'] == 1) {
                  localStorage.setItem('Employe_Role', JSON.stringify(employeRole))
                } else {

                }
              }

              commit('setEmployeeNames', employeeList)
            } else {

            }

          }
        }, 1100);
      }).catch(err => {
        if (err.response.status == 401) {
          expire.openNotification({
            title: "Unauthorized",
            type: "danger",
            duration: 3000,
          });
        } else if (err.response.status >= 500) {
          expire.openNotification({
            title: "Something went wrong,Please try again",
            type: "danger",
            duration: 3000,
          });
          router.push('/').catch(() => {})
        }
      }).finally(() => {


      })
    },

    /**
     * Method to get Task Details
     * @author Saran Raj
     * @since 15/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    async getTasklistNew({
      commit,
      state,
      rootGetters,
      dispatch
    }, payload) {

      commit('setLoading', true)
      let JsonData = {
        "token": rootGetters['userData/userToken']
      }
      await service.getTasklist(JsonData).then((response) => {
        if (response.status == 200) {
          if (response.data['status'] == 1) {
            var individual = []
            for (let item of response.data['result']) {
              var empID = JSON.parse(localStorage.getItem('setUserID'))
              if (item.assigned_to == empID) {
                individual.push(item)
              }
            }
            commit('setIndividualTaskList', individual)
            commit('setTaskrecords', response.data['result']);
          } else {

          }
        }
      }).catch(error => {
        if (error.response.status == 401) {
           expire.openNotification({
            title: "Unauthorized",
            type: "danger",
            duration: 2000,
          });
          router.push("/").catch(() => {});
        }
      }).finally(() => {
        commit('setLoading', false)
      })
    },

    /**
     * Method to get Customer Details
     * @author Saran Raj
     * @since 17/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    async getCustomerData({
      commit,
      state
    }, payload) {

      commit('setLoading', true)
      await service.getCustomerlist().then((response) => {
        if (response.status == 200) {
          if (response.data['status'] == 1) {
            commit('setCustomerList', response.data['result']);
            var customerNames = []
            for (let idx of response.data['result']) {
              if (idx['cust_name'] != null && idx['cust_name'] != " ") {
                customerNames.push(idx['cust_name']);
              }
            }
            commit("setCustomerNames", customerNames)
          }
          else if( response.status == 401){
            expire.openNotification({
              title: "Unauthorized",
              type: "danger",
              duration: 2000,
            });
            router.push("/").catch(() => {});
          } else {
            console.log('Response Failed')
          }
        }
      }).catch(error => {
        if (error.response.status === 401) {
          expire.openNotification({
            title: "Unauthorized",
            type: "danger",
            duration: 2000,
          });
          router.push("/").catch(() => { });
        }
      }).finally(() => {
        commit('setLoading', false)
      });
    },

    /**
     * Method to get Project Details
     * @author Saran Raj
     * @since 22/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    async getProjectData({
      commit,
      state,
      dispatch,
    }, payload) {

      commit('setLoading', true)
      await service.projectDetails().then((response) => {

        if (response.status == 200) {
          if (response.data['status'] == 1) {
            commit('projectRecord', response.data.result)
            var projectNames = []
            var managerNames = []
            for (let idx of response.data['result']) {
              if (idx['project_name']) {
                projectNames.push(idx['project_name']);
              }
              if (idx['manager_name']) {
                managerNames.push(idx['manager_name'])
              } else if (response.data.result == "Session Expired") {
                errorHandling.sessionExpire()
              }
            }
            commit('setProjectList', response.data['result'])
            commit('setManagerData', managerNames)
            commit('setProjectData', projectNames)
          } else {
            console.log('Response Failed')
          }
        }

      }).catch(error => {
        if (error.response.status == 401) {
          expire.openNotification({
            title: "Unauthorized",
            type: "danger",
            duration: 2000,
          });
          router.push("/").catch(() => {});
        }
      }).finally(() => {
        setTimeout(() => {
          commit('setLoading', false)
        }, 1100);
      })
    },

    /**
     * Method to get Report List
     * @author Jothi
     * @since 19/12/2021
     * @updatedOn :
     * @updatedBy
     /** */

    async getReportsList({
      commit
    }, payload) {

      let jsonData = {
        "start_Date": payload.currentDate,
        "end_Date": payload.endDate,
        "dateProvided": "true"
      }
      commit('setLoading', true)
      await service.getReportsdetails(jsonData).then(response => {
        setTimeout(() => {
          commit('setLoading', false)
         
          if (response.status == 200) {
            if (response.data['status'] == 1) {
              var RecordList = []
              var RecordList = response.data.result
              for (let idx in RecordList) {
                if (RecordList[idx]['avghours'] == 0) {
                  RecordList[idx]['calculatedHours'] = '00' + ':' + '00'
                } else {
                  var hours = Math.floor(RecordList[idx]['avghours'] / 60);
                  var minutes = RecordList[idx]['avghours'] % 60;
                  var hour = hours < 10 ? '0' + hours : hours
                  var minute = minutes < 10 ? '0' + minutes : minutes
                  RecordList[idx]['calculatedHours'] = hour + ':' + minute;
                }
                if (RecordList[idx]['actual_hour'] == 0) {
                  RecordList[idx]['calculatedHours1'] = '00' + ':' + '00';
                } else {
                  var h = Math.floor(RecordList[idx]['actual_hour'] / 60);
                  var m = RecordList[idx]['actual_hour'] % 60;
                  var hr = h < 10 ? '0' + h : h;
                  var min = m < 10 ? '0' + m : m;
                  RecordList[idx]['calculatedHours1'] = hr + ':' + min;
                }
                if (RecordList[idx]['avgofficehours'] == 0) {
                  RecordList[idx]['avgofficehours'] = '00' + ':' + '00';
                } else {
                  var hoursday = Math.floor(RecordList[idx]['avgofficehours'] / 60 / RecordList[idx]['check_out']);
                  var hoursmin = RecordList[idx]['avgofficehours'] % 60 / RecordList[idx]['check_out'];
                  var hourday = hoursday < 10 ? '0' + hoursday : hoursday;
                  var minuteday = hoursmin < 10 ? '0' + hoursmin : hoursmin
                  var decimalday = minuteday.toString();
                  var finaldecimal = decimalday.split('.')[0];
                  RecordList[idx]['avgofficehours'] = hourday + ':' + finaldecimal;
                }
                if (RecordList[idx]['avgofficehours'] == 0) {
                  RecordList[idx]['Logged'] = "00%"
                } else {
                  var Logged = RecordList[idx]['avgofficehours'].replace(":", ".");
                  RecordList[idx]['Logged'] = Math.round(parseFloat(((Logged / 8) * 100).toFixed(1))) + "%"
                }
              }
              
              commit('setRecordList', RecordList);
              commit('setReportDetails', RecordList)
             

            } else {
              expire.openNotification({
                title: "No Data Found",
                type: "danger",
                duration: 2000,
              });
            }
          }
        }, 1100);
      }).catch(error => {
        if (error.response.status == 401) {
          expire.openNotification({
            title: "Unauthorized",
            type: "danger",
            duration: 2000,
          });
          router.push("/").catch(() => {});
        }
      }).finally(() => {
        commit('setLoading', false)
      })
    },
    /**
     * Method to call Create Project
     * @author Dhivya
     * @since 19/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    async insertProject({
      state,
      commit,
      rootGetters,
      dispatch,
    }, payload) {
      commit('setDailogloading', true)
      // var arr = JSON.parse(localStorage.getItem('projectId'))
      var customerID = ''
      var managerkey = ''
      // arr.forEach(el => {
      //   if (el.cust_name == payload.cusName) {
      //     customerID = el.cust_id;
      //   }
      // })
      // arr.forEach(el => {
      //   if (el.manager_name == payload.managerId) {
      //     managerkey = el.manager;
      //   }
      // })
      rootGetters['getCustomer'].forEach(el => {
        if (el.cust_name == payload.cusName) {
          customerID = el.cust_id;
        }
      })
      rootGetters['getProject'].forEach(el => {
        if (el.manager_name == payload.managerId) {
          managerkey = el.manager;
        }
      })
      let jsonData = {
        "project_name": payload.project,
        "project_short_name": payload.projShortName,
        "cust_id": customerID,
        "manager": managerkey,
        "type": payload.typeInfo,
        "billable": payload.toggleEnable,
      }
      commit('setLoading', true)
      await service.projectCreation(jsonData).then((response) => {

        if (response.status == 200) {
          if (response.data['status'] == 1) {
            dispatch("getProjectData")
            commit('setProjectDetails', response.data)

            expire.openNotification({
              title: "Project created successfully",
              type: "success",
              duration: 2000,
            })

          } else {
            expire.openNotification({
              title: "Project creation failed, please try again",
              type: "danger",
              duration: 2000,
            })
          }
        }

      }).finally(() => {
        commit('setDailogloading', false)
      })
    },

    /**
     * Method to call Create Customer List
     * @author Saran Raj
     * @since 23/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    insertCustomer({
      state,
      commit,
      dispatch
    }, payload) {

      commit('setLoading', true)
      let jsonData = {
        "cust_name": payload.customerData,
        "cust_short_name": payload.customerShortName,
        "address_1": payload.addressOne,
        "address_2": payload.addressTwo,
        "contact_mobile": payload.mobileNumber,
        "contact_email": payload.customerEmail,
        "spoc_name": payload.spocNumber,
        "logo": "logo"
      }

      service.customerDetails(jsonData).then((response) => {
        if (response.status == 200) {
          if (response.data['status'] == 1) {
            dispatch("getCustomerData")
            commit('setCustomerDetails', response.data)
            expire.openNotification({
              title: "Customer created successfully",
              type: "success",
              duration: 2000,
            });
          } else {
            expire.openNotification({
              title: "Customer creation failed , please try again",
              type: "danger",
              duration: 2000,
            });
          }
        }

      }).catch(err => {
        if (err.response.status == 401) {
          expire.openNotification({
            title: "Unauthorized",
            type: "danger",
            duration: 2000,
          });
          router.push("/").catch(() => {});
        }
      }).finally(() => {
        commit('setLoading', false)
      })
    },

    /**
     * Method to call Create Task
     * @author Saran Raj
     * @since 23/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    createTaskDetails({
      state,
      commit,
      rootGetters,
      dispatch
    }, payload) {

      var managerId = ''
      rootGetters['getProject'].forEach(el => {
        if (el.project_name == payload.projectType) {
          projId = el.project_id;
        }
      })
      rootGetters['getProject'].forEach(el => {
        if (el.manager_name == payload.managerID) {
          managerId = el.manager;
        }
      })
      const formdata = new FormData();
      formdata.append("task", JSON.stringify({
        task_name: payload.taskName,
        description: payload.descriptionData,
        start_date: payload.startDate,
        end_date: payload.endDate,
        task_status: payload.status,
        est_hour: payload.estimate,
        assigned_to: payload.assignedTo,
        task_manager: managerId,
        priority: payload.prioritystatus,
        emp_id: rootGetters['userlogin/getTokenData'],
        created_by: rootGetters['userlogin/getTokenData'],
        updated_by: rootGetters['userlogin/getTokenData'],
        project_id: payload.projectType
      }));
      service.createTask(formdata).then((response) => {
        if (response.status == 200) {
          if (response.data['status'] == 1) {
            commit('TaskDetails', response.data)
            expire.openNotification({
              title: "Task created successfully",
              type: "success",
              duration: 2000,
            });
           
            dispatch("task/getTasks", {
              root: true
            })
          } else {
            expire.openNotification({
              title: "Task creation failed, please try again",
              type: "danger",
              duration: 2000,
            });
          }
        }
        
      }).catch(error => {
        // if (error.response.status == 401) {
        //   expire.openNotification({
        //     title: "Unauthorized",
        //     type: "danger",
        //     duration: 3000,
        //   });
        //   router.push("/").catch(() => {});
        // }
        console.log(error);
      }).finally(() => {
        commit('setLoading', false)
      })
    },


    /**
     * Method to call Update Task Details
     * @author Saran Raj
     * @since 27/12/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    updateTaskDetails({
      commit,
      rootGetters,
      dispatch
    }, payload) {
      const formdata = new FormData();
      formdata.append('taskupdate', JSON.stringify({
        "task_id": payload.taskid,
        "comments": payload.comment,
        "minutes": payload.actualhour,
        "assigned_to": payload.assignedTo,
        "priority": payload.priorityStatus,
        "task_status": payload.taskStatus,
        "id": payload.id
      }));

      service.updateTask(formdata).then((response) => {
        if (response.status == 200) {
          if (response.data['status'] == 1) {
           
            dispatch('task/getTasks', {
              root: true
            })
            commit('UPDATE_TASK', response.data);
            expire.openNotification({
              title: "Task updated successfully",
              type: "success",
              duration: 2000,
            });
          } else {
            expire.openNotification({
              title: response.data['reson'],
              type: "danger",
              duration: 1000,
            });

          }
        }
        (err) => {
          console.log(err)
        }
      })
    },

    /**
     * Method to get Hours List
     * @author Jothi
     * @since 05/01/2021
     * @updatedOn :
     * @updatedBy
     * @return na
     */

    async getHoursList({
      commit
    }, payload) {
      let jsonData = {
        "start_Date": payload.currentDate,
        "end_Date": payload.endDate,
        "dateProvided": "true"
      }
      commit('setLoading', true)
      await service.getHoursdetails(jsonData).then(response => {
        if (response.status == 200) {
          if (response.data['status'] == 1) {
            var TaskhourList = []
            var TaskhourList = response.data.result
            for (let idx in TaskhourList) {
              if (TaskhourList[idx]['total_office_hours'] == 0) {
                TaskhourList[idx]['total_office_hours'] = '00' + ':' + '00'
              } else {
                var hours = Math.floor(TaskhourList[idx]['total_office_hours'] / 60);
                var minutes = TaskhourList[idx]['total_office_hours'] % 60;
                var hour = hours < 10 ? '0' + hours : hours
                var minute = minutes < 10 ? '0' + minutes : minutes
                TaskhourList[idx]['total_office_hours'] = hour + ':' + minute;
              }
              if (TaskhourList[idx]['avgofficehours'] == 0) {
                TaskhourList[idx]['avgofficehours'] = '00' + ':' + '00';
              } else {
                var hoursday = Math.floor(TaskhourList[idx]['avgofficehours'] / 60);
                var hoursmin = TaskhourList[idx]['avgofficehours'] % 60;
                var hourday = hoursday < 10 ? '0' + hoursday : hoursday;
                var minuteday = hoursmin < 10 ? '0' + hoursmin : hoursmin
                var decimalday = minuteday.toString();
                var finaldecimal = decimalday.split('.')[0];
                TaskhourList[idx]['avgofficehours'] = hourday + ':' + finaldecimal;
              }
              if (TaskhourList[idx]['total_task_hours'] == 0) {
                TaskhourList[idx]['total_task_hours'] = '00' + ':' + '00'
              } else {
                var h = Math.floor(TaskhourList[idx]['total_task_hours'] / 60);
                var m = TaskhourList[idx]['total_task_hours'] % 60;
                var hr = h < 10 ? '0' + h : h;
                var min = m < 10 ? '0' + m : m;
                TaskhourList[idx]['total_task_hours'] = hr + ':' + min;
              }
              if (TaskhourList[idx]['avgtaskhours'] == 0) {
                TaskhourList[idx]['avgtaskhours'] = '00' + ':' + '00';
              } else {
                var hoursday = Math.floor(TaskhourList[idx]['avgtaskhours'] / 60);
                var hoursmin = TaskhourList[idx]['avgtaskhours'] % 60;
                var hourday = hoursday < 10 ? '0' + hoursday : hoursday;
                var minuteday = hoursmin < 10 ? '0' + hoursmin : hoursmin
                var decimalday = minuteday.toString();
                var finaldecimal = decimalday.split('.')[0];
                TaskhourList[idx]['avgtaskhours'] = hourday + ':' + finaldecimal;
              }
            }
            // console.log(TaskhourList, '********')
            commit('setTaskhourList', TaskhourList);
            commit('setTaskreport', TaskhourList)
            commit('setReport', TaskhourList)
            // console.log(TaskhourList, "Report Data");

          } else {
            expire.openNotification({
              title: "No Data Found",
              type: "danger",
              duration: 3000,
            });
          }
        }
      }).catch(err => {
        if (err.response.status == 401) {
          expire.openNotification({
            title: "Unauthorized",
            type: "danger",
            duration: 3000,
          });
          router.push("/").catch(() => {});
        }
      }).finally(() => {
        commit('setLoading', false)
      })
    },
    /**
     * Method to show comments Details
     * @author Jothi
     * @since 24/01/2022
     * @updatedOn :
     * @updatedBy
     * @return na
     */
    async getcomments({
      commit
    }, payload) {
      service.showcomments(payload).then((response) => {

        if (response.data.message == 'sucesss') {
          commit('setComments', response.data.result)
        }
      })
    },

    async userCount({
      state,
      commit
    }, payload) {
      await service.userCount(payload).then((response) => {
        if (response.status == 200) {
          if (response.data['status'] == 1) {
            commit('SET_USER_COUNT', response.data.result)
            console.log(response.data.result);
            console.log(state.userCount);
          } else if (response.data['result'] == '') {
            expire.openNotification({
              title: "No Data Found",
              type: "danger",
              duration: 3000,
            });
          } else {

            commit('SET_USER_COUNT', [])
          }
        }
      })
    },

  //  async userActive({ state, commit}, payload) {
  //   await service.userActiveStatus(payload).then((response) => {
  //     if (response.status == 200) {
  //       if (response.data['status'] == 1) {
  //         commit('SET_ACTIVE_LIST', response.data.result)
  //         console.log(response.data.result);

  //         console.log();

  //       } else {

          
  //       }
  //     }
  //   })
  //  }
    
  },
  getters: {
    getCustomer: state => {
      return state.customerLists
    },

    getTaskRecords: (state) => {
      state.taskLists
    },
    getcustomerData(state) {
      return state.customerResponse
    },
    getProject: state => {
      return state.projecttList
    },
    getLoader : state => {
        return state.loading
    },
    getTaskData: (state) => state.projectData,
    getTask: (state) => state.UserTasks,
    getEmployeData: (state) => state.employeeList,
    getUserCount: state => state.TOTALCOUNT,
    getActiveList: state => state.setActivUser,
   
    getExpandPanel: state => {
      return state.expandPanel
    },
    getPanelWidth: state => {
      return state.panelWidth
    },
    getReports: state => {
      return state.empRecords
    },
  getUpdateTaskPanel: state => {
    return state.updateTaskPanel
  }
  },
  modules: {
    userData,
    task
  }
})