import service from '../js/http-service'
import router from '@/router'
import expire from '../../mixins/expire.js'

import errorHandling from '../js/error-handling.js'

const userData = {
    namespaced: true,
    State: {

        tokenkey: [],
        emailid: '',
        emp_email: '',
        emp_id: '',
        userDetailsData: '',
        checkindata: '',
        emp_name: '',
        userId: '',
        empData: "",
        adminRole: '',

        // SERIES: [{
        //     name: 'Check-IN',
        //     data: []
        // }, {
        //     name: 'Check-OUT',
        //     data: []
        // }, {
        //     name: 'Total-Users',
        //     data: []
        // }],
        // chartOptions: {
        //     chart: {
        //         height: 350,errLog
        //         type: 'bar',
        //         zoom: {
        //             enabled: false
        //         },
        //         animations: {
        //             enabled: false
        //         }
        //     },
        //     stroke: {
        //         width: [5, 5, 4],
        //         curve: 'smooth'
        //     },
        //     labels: ['Mon'],
        //     title: {
        //         text: ''
        //     },
        //     xaxis: {
        //         data: []
        //     },
        //     toolbar: {
        //         show: false,
        //         tools: {
        //             download: false // <== line to add
        //         }
        //     }
        // },

    },
    mutations: {
        setLoader(state, payload) {
            state.loader = payload
        },
        loginDetails(state, payload) {
            state.userDetailsData = payload
            localStorage.setItem('currentUserData', JSON.stringify(payload));
        },
        userToken(state, payload) {
            state.tokenkey = payload
            localStorage.setItem('settokenId', JSON.stringify(payload));
        },
        userID(state, payload) {
            state.emp_email = payload
            localStorage.setItem('userEmail', JSON.stringify(payload));
        },
        employeeID(state, payload) {
            state.emp_id = payload
            this.empData = localStorage.setItem('setUserID', JSON.stringify(payload));

        },
        empRole(state, payload) {
            state.emp_role = payload
            localStorage.setItem('adminRole', JSON.stringify(payload));

        },
        setCheckin(state, payload) {
            state.checkindata = payload
        },

        employeeName(state, payload) {
            state.emp_name = payload
            localStorage.setItem('empName', JSON.stringify(payload));
        },

    },
    actions: {
        /**
         * Method to call Uselogin
         * @author Saran Raj
         * @since 10/12/2021
         * @updatedOn :
         * @updatedBy
         * @return na
         */
        async userLogin({ state, commit, dispatch }, payload) {
            commit('setLoading', true, {
                root: true
            })
            await service.getLogindetails(payload).then(response => {
                if (response.status == 200) {
                    if (response.data['status'] == 1) {
                        commit('userToken', response.data.result.tokenkey);
                        commit('userID', response.data.result.emp_email);
                        commit('employeeID', response.data.result.emp_id);
                        commit('employeeName', response.data.result.emp_name);
                        commit('empRole', response.data.result.emp_role);
                        commit('loginDetails', response.data)
                        dispatch("userCheckin", )
                        localStorage.setItem('currentUserData', JSON.stringify(response.data.result));
                        router.push("Dashboard").catch(() => {});

                    } else if (response.data['status'] == 0) {
                        expire.openNotification({
                            title: response.data.message,
                            type: "danger",
                            duration: 2000,
                        });
                    }
                }

            }, (err)=>{
                errorHandling.errLog(err)
            }).finally(() => {
                commit('setLoading', false, {
                    root: true
                })
            })
        },

        /**
         * Method to call User Checkin
         * @author Saran Raj
         * @since 18/12/2021
         * @updatedOn :
         * @updatedBy
         * @return na
         */

        async userCheckin({
            state,
            commit
        }, payload) {
            try {
                let json = {
                    "emp_id": localStorage.getItem('setUserID')
                }
                commit('setLoading', true, {
                    root: true
                })
                await service.userCheckIn(json).then((response) => {
                    if (response.status == 200) {
                        if (response.data['status'] == 1) {
                            commit('setCheckin', response.data)
                            expire.openNotification({
                                title: "User checkin successfully",
                                type: "success",
                                duration: 1000,
                            });
                        } else {
                            expire.openNotification({
                                title: response.data['message'],
                                type: "danger",
                                duration: 1000,
                            });
                        }
                    }
                })
            } catch (error) {
                commit('setLoading', false, {
                    root: true
                })
            }
        },

        /**
         * Method to call User Checkout
         * @author Saran Raj
         * @since 20/12/2021
         * @updatedOn :
         * @updatedBy
         * @return na
         */

        async userCheckOut({ state, commit }, payload) {
            
            let json = {
                "emp_id": localStorage.getItem('setUserID')
            }
            commit('setLoading', true, {
                root: true
            })
            await service.userCheckedOut(json).then((response) => {
                if (response.status == 200) {
                    if (response.data['status'] > 0) {
                        localStorage.clear();
                        expire.openNotification({
                            title: "User checkout successfully",
                            type: "danger",
                            duration: 1000,
                        });
                    } else {

                    }
                }
            }).finally(() => {
                commit('setLoading', false, {
                    root: true
                })
            })
        },

        //


    },

    getters: {

        getEmpName: state => state.emp_name,
        getUserData(state) {
            return state.userDetailsData
        },
        getTokenID: state => {
            return state.tokenkey
        },
        getEmployeEmail(state) {
            return state.emp_email
        },
        getEmpId(state) {
            return state.emp_id
        },

    },
}
export default userData