import service from '../js/http-service'
import router from '@/router'
import expire from '../../mixins/expire.js'


const task = {
    namespaced: true,
    state: {
        totalTasks: [],
        currentUserTaks: [],
        currentTasks: [],
        commentsData: [],
        openTasks: [],
        inProgressTasks: [],
        fixedTasks: [],
        cancelledTasks: [],
        pendingTasks: [],
        completedTasks: [],
        reviewTasks: [],
        customerTasks: [],
        priorityTask: [],
        criticalTask: [],
        allTasksList: [],
        allPriorityTasks: [],
        employeeRole: ""
    },
    mutations: {
        setTasks(state, payload) {
            state.totalTasks = payload
            this.commit('task/setCurrentUserTaks', payload)
            this.commit('task/setAllTypesTasks', payload)
            this.commit('task/allTasks', payload)
            // this.commit('task/setPriorityTask', payload)
        },
        setCurrentTasks(state, payload) {
            state.currentTasks = payload
        },
        allTasks(state, payload) {
            state.allTasksList = payload

        },

        setCurrentUserTaks(state, payload) {
            state.currentUserTaks = []
            var empId = JSON.parse(localStorage.getItem('currentUserData')).emp_id
            payload.forEach(el => {
                el.assigned_to == empId ? state.currentUserTaks.push(el) : ''
            });
        },

        setAllTypesTasks(state, payload) {
            // set different types of tasks
            let openTasks = []
            let inProcTasks = []
            let fixedTasks = []
            let cancelledTasks = []
            let completedTasks = []
            let pendingTasks = []
            let reviewTasks = []
            let priorityTask = []
            let allPriorityTasks = []
            let criticalTask = []
            let closedDuplicate = []
            let closedRejected = []
            let closedAbsolute = []
            var empId = (localStorage.getItem('setUserID'))
            var empRoleId = (localStorage.getItem('adminRole') == 1)

            payload.forEach(el => {
                if (el.task_status == 'Open') {
                    openTasks.push(el)
                } else if (el.task_status == 'In-Progress') {
                    inProcTasks.push(el)
                } else if (el.task_status.toLowerCase() == 'Pending') {
                    pendingTasks.push(el)
                } else if (el.task_status == 'Review') {
                    reviewTasks.push(el)
                } else if (el.task_status == 'Cancelled') {
                    cancelledTasks.push(el)
                } else if (el.task_status == 'Fixed') {
                    fixedTasks.push(el)
                } else if (el.task_status == "Open-Priority") {

                    if (el.assigned_to == empId) {
                        priorityTask.push(el)
                    }
                    if (empRoleId == true) {
                        priorityTask.push(el);
                    }

                } else if (el.task_status == 'Critical') {
                    if (el.assigned_to == empId) {
                        criticalTask.push(el)
                    }
                }

            })

            this.commit('task/setOpenTasks', openTasks)
            this.commit('task/setInProgressTasks', inProcTasks)
            this.commit('task/setFixedTasks', fixedTasks)
            this.commit('task/setCancelledTasks', cancelledTasks)
            this.commit('task/setPendingTasks', pendingTasks)
            this.commit('task/setCompletedTasks', completedTasks)
            this.commit('task/setReviewTasks', reviewTasks)
            this.commit('task/setPriorityTask', priorityTask)
            this.commit('task/setCriticalTask', criticalTask)
        },

        setCommentsData(state, payload) {
            state.commentsData = payload
        },

        setOpenTasks(state, payload) {
            state.openTasks = payload
        },

        setInProgressTasks(state, payload) {
            state.inProgressTasks = payload
        },

        setFixedTasks(state, payload) {
            state.fixedTasks = payload
        },

        setCancelledTasks(state, payload) {
            state.cancelledTasks = payload
        },

        setPendingTasks(state, payload) {
            state.pendingTasks = payload
        },

        setCompletedTasks(state, payload) {
            state.completedTasks = payload
        },

        setReviewTasks(state, payload) {
            state.reviewTasks = payload
        },

        setClosedDuplicate(state, payload) {
            state.closedDuplicateTasks = payload
        },

        setClosedRejected(state, payload) {
            state.closedRejectedTasks = payload
        },

        setClosedAbsolute(state, payload) {
            state.closedAbsoluteTasks = payload
        },

        setSortTasks(state, sortBool) {
            if (sortBool) {
                state.currentTasks.sort((a, b) =>
                    b.task_name > a.task_name ? 1 : a.task_name > b.task_name ? -1 : 0
                )
            } else {
                state.currentTasks.sort((a, b) =>
                    a.task_name > b.task_name ? 1 : b.task_name > a.task_name ? -1 : 0
                )
            }

            this.commit('task/setCurrentTasks', state.currentTasks)
        },

        setCustomerTasks(state, payload) {
            state.customerTasks = payload
        },

        setPriorityTask(state, payload) {
            state.priorityTask = payload
            state.priorityTask.sort((old, current) => {
                return new Date(old.end_date) - new Date(current.end_date)
            })


            state.priorityTask.forEach(el => {
                let diff = new Date() - new Date(el.end_date);
                let diff2 = new Date(el.end_date).toISOString().slice(0, 10)
                // var today = new Date().toISOString().slice(0, 10)               
                // var yesterday1 = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);

                if (diff < 0) {
                    el.task_type = "future"
                } else if (diff > 0) {
                    el.task_type = "past"
                } else if (new Date(el.end_date) >= diff) {
                    el.task_type = "current"
                }
            })


        },
        setCriticalTask(state, payload) {
            state.criticalTask = payload

            state.criticalTask.sort((old, current) => {
                return new Date(old.end_date) - new Date(current.end_date)
            })

            state.criticalTask.forEach(el => {
                let diff = new Date().getTime() - new Date(el.end_date).getTime();
                var yesterday1 = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);

                if (diff < 0) {
                    el.task_type = "future"

                } else if (diff > 0) {
                    el.task_type = "past"

                } else if (el.end_date.getTime() == today.getTime()) {
                    el.task_type = "current"

                }
            })
        }
    },
    actions: {
        async getTasks({
            commit
        }) {
            commit('setLoading', true, {
                root: true
            })

            await service.getTasklist().then(response => {
                if (response.status == 200 && response.data.result) {
                    commit('setTasks', response.data.result);
                    commit('allTasks', response.data.result)
                } else {
                    commit('setTasks', []);
                }
            }).catch(error => {

                if (error.response.status == 401) {
                    expire.openNotification({
                        title: "Unauthorized",
                        type: "danger",
                        duration: 3000,
                    });
                    router.push("/").catch(() => {});
                }



            }).finally(() => {
                commit('setLoading', false, {
                    root: true
                })
            })

        },

        async createTask({
            commit,
            dispatch
        }, payload) {
            await service.createTask(payload).then(response => {
                if (response.status == 200) {
                    dispatch('getTasks');
                }
            }).catch(error => {

            }).catch(error => {

                if (error.response.status == 401) {
                    expire.openNotification({
                        title: "Unauthorized",
                        type: "danger",
                        duration: 3000,
                    });
                    router.push("/").catch(() => {});
                }

            }).finally(() => {
                commit('setLoading', false, {
                    root: true
                })
            })
        },

        async updateTask({
            commit,
            dispatch
        }, payload) {
            await service.updateTask(payload).then(response => {
                if (response.status == 200) {
                    dispatch('getTasks');
                }
            }).catch(error => {

                if (error.response.status == 401) {
                    expire.openNotification({
                        title: "Unauthorized",
                        type: "danger",
                        duration: 3000,
                    });
                    router.push("/").catch(() => {});
                }

            }).finally(() => {
                commit('setLoading', false, {
                    root: true
                })
            })
        },

        async taskStatusUpdate({
            commit,
            dispatch
        }, payload) {
            await service.statusUpdate(payload).then(response => {
                if (response.status == 200) {
                    dispatch('getTasks');
                }
            }).catch(error => {

                if (error.response.status == 401) {
                    expire.openNotification({
                        title: "Unauthorized",
                        type: "danger",
                        duration: 3000,
                    });
                    router.push("/").catch(() => {});
                }

            }).finally(() => {
                commit('setLoading', false, {
                    root: true
                })
            })
        },

        async getComments({
            commit
        }, payload) {
            await service.getComments(payload).then(response => {
                if (response.status == 200 && response.data.result) {
                    commit('setCommentsData', response.data.result);
                } else {
                    commit('setCommentsData', []);
                }
            }).catch(error => {

                if (error.response.status == 401) {
                    expire.openNotification({
                        title: "Unauthorized",
                        type: "danger",
                        duration: 3000,
                    });
                    router.push("/").catch(() => {});
                }

            }).finally(() => {})
        },

        async getCustomerTasks({
            commit,
            rootGetters
        }, payload) {
            var projId = localStorage.getItem('clientProjectId')
            commit('setLoading', true, {
                root: true
            })
            await service.getCustomerTasks({
                project_id: projId
            }).then(response => {
                if (response.status == 200 && response.data.result) {
                    commit('setCustomerTasks', response.data.result);
                } else {
                    commit('setCustomerTasks', []);
                }
            }).catch(error => {

                if (error.response.status == 401) {
                    expire.openNotification({
                        title: "Unauthorized",
                        type: "danger",
                        duration: 3000,
                    });
                    router.push("/").catch(() => {});
                }

            }).finally(() => {
                commit('setLoading', false, {
                    root: true
                })
            })
        }
    },
    getters: {
        getAllTasks: state => {
            return state.totalTasks;
        },
        getCurrentUserTaks: state => {
            return state.currentUserTaks;
        },
        getCurrentTasks: state => {
            return state.currentTasks;
        },
        getCommentsData: state => {
            return state.commentsData;
        },

        // tasks different's types
        getOpenTasks: state => {
            return state.openTasks;
        },
        getInProgressTasks: state => {
            return state.inProgressTasks;
        },
        getFixedTasks: state => {
            return state.fixedTasks;
        },
        getCancelledTasks: state => {
            return state.cancelledTasks;
        },
        getPendingTasks: state => {
            return state.pendingTasks;
        },
        getCompletedTasks: state => {
            return state.completedTasks;
        },
        getReviewTasks: state => {
            return state.reviewTasks;
        },
        getCustomerTasks: state => {
            return state.customerTasks;
        },
        getPriorityTask: state => {
            return state.priorityTask
        },
        getCriticalTask: state => {
            return state.criticalTask
        }
    }
}
export default task;