<template>
  <router-view />
</template>

<script>
  import router from '@/router'
import './assets/css/tailwind.css'
export default {
  name: 'App',

  data: () => ({

  }),

  created(){
   
  },
  mounted() {
   

    
  },

  created(){
   
  }
};
</script>

<style>
/* ////new csss */

.fsize10 {
  font-size: 10px !important;
}

.fsize11 {
  font-size: 11px !important;
}

.fsize12 {
  font-size: 13px !important;
}

.fsize13 {
  font-size: 13px !important;
}

.fsize14 {
  font-size: 14px !important;
}

.fsize16 {
  font-size: 16px !important;
}

.fsize18 {
  font-size: 18px !important;
}

.fsize20 {
  font-size: 20px !important;
}

.fsize24 {
  font-size: 24px !important;
}

.cursor {
  cursor: pointer !important;
}

.row {
  margin: 0 !important;
}

.font-bold {
  font-weight: bold;
}

.mx-width-320 {
width: 320px !important;
}
.mx-width-300{
  max-width: 300px !important;
}
.is-invalid {
  border: 1px solid red !important;
  margin-top: 2px;
}

.text-danger {
  color: red;
}

table th {
  font-weight: normal !important;
  font-size: 11px !important;
}

.v-btn {
  letter-spacing: 0px !important
}

.v-application .caption {
  letter-spacing: 0em !important;
}

@font-face {
  font-family: "Lato";
  src: local("fonts"), url(./assets/font/Lato-Regular.ttf);
}

* {
  font-family: "lato";
}

table th {
  font-size: 14px !important;
  font-weight: bold !important;

}

.fontweight {
  font-weight: bold !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  color: black !important;
}

.settings {
  color: white !important;
}

textarea {
  resize: none !important;
  overflow: hidden !important;
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type=time]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.echarts {
  width: 100% !important;
}
.f-right{
   float: right !important;
}
.lh-80 {
  line-height: 80px !important
}

.custom-card.v-sheet.v-card {
  border-radius: 12px !important;
}
 .bg-voilet{
  background-color: #0D263B !important;
 }
.word-spacing {
  word-spacing: 12px !important;
}
</style>